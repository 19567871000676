<template>
  <div class="row mx-0 vh-100">
    <div class="col-12 p-0 h-25 position-relative">
      <img src="../assets/fluss_icon_with_shadow_blue_bg.svg" class="bg_image">
      <div class="profile_picture_holder position-absolute top-100 start-50 translate-middle">
        <img :src="require('../static/images/' + theContact[0].slug + '.png')" class="profile_picture">
      </div>
    </div>
    <div class="col-12 p-0 h-75">
      <div class="row mx-0 pt justify-content-center">
        <div class="col-12 fs-3 fw-bold text-center">
          {{theContact[0].name}}
        </div>
        <div class="col-12 text-center">
          {{theContact[0].position}}
        </div>
        <div class="col-9 mt-2 text-center small" v-html="theContact[0].blurb">
        </div>
      </div>
      <div class="row mx-0 my-4">
        <div class="col-12">
          <button class="btn btn-dark" @click="vcard" >
            Save Contact
          </button>
        </div>
      </div>
      <div class="row mx-0 justify-content-center">
        <div class="col-10">
          <div class="row row-cols-3 mx-0 justify-content-center">
            <div class="col p-2 text-center">
              <div class="row mx-0 border border-2 rounded p-2 pointer" @click="call">
                <div class="col-12 fs-2 p-0">
                  <BIconTelephone />
                </div>
                <div class="col-12 small p-0">
                  Call
                </div>
              </div>
            </div>
            <div class="col p-2 text-center">
              <div class="row mx-0 border border-2 rounded p-2 pointer" @click="email">
                <div class="col-12 fs-2 p-0">
                  <BIconEnvelope />
                </div>
                <div class="col-12 small p-0">
                  Email
                </div>
              </div>
            </div>
            <div class="col p-2 text-center">
              <div class="row mx-0 border border-2 rounded p-2 pointer" @click="text">
                <div class="col-12 fs-2 p-0">
                  <BIconChat />
                </div>
                <div class="col-12 small p-0">
                  Text
                </div>
              </div>
            </div>
            <div class="col p-2 text-center">
              <div class="row mx-0 border border-2 rounded p-2 pointer" @click="whatsapp">
                <div class="col-12 fs-2 p-0">
                  <BIconWhatsapp />
                </div>
                <div class="col-12 small p-0">
                  WhatsApp
                </div>
              </div>
            </div>
            <div class="col p-2 text-center">
              <div class="row mx-0 border border-2 rounded p-2 pointer" @click="facebook">
                <div class="col-12 fs-2 p-0">
                  <BIconFacebook />
                </div>
                <div class="col-12 small p-0">
                  Facebook
                </div>
              </div>
            </div>
            <div class="col p-2 text-center">
              <div class="row mx-0 border border-2 rounded p-2 pointer" @click="instagram">
                <div class="col-12 fs-2 p-0">
                  <BIconInstagram />
                </div>
                <div class="col-12 small p-0">
                  Instagram
                </div>
              </div>
            </div>
            <div class="col p-2 text-center">
              <div class="row mx-0 border border-2 rounded p-2 pointer" @click="twitter">
                <div class="col-12 fs-2 p-0">
                  <BIconTwitter />
                </div>
                <div class="col-12 small p-0">
                  Twitter
                </div>
              </div>
            </div>
            <div class="col p-2 text-center">
              <div class="row mx-0 border border-2 rounded p-2 pointer" @click="website">
                <div class="col-12 fs-2 p-0">
                  <BIconGlobe2 />
                </div>
                <div class="col-12 small p-0">
                  Website
                </div>
              </div>
            </div>
            <div class="col p-2 text-center">
              <div class="row mx-0 border border-2 rounded p-2 pointer" @click="linkedin">
                <div class="col-12 fs-2 p-0">
                  <BIconLinkedin />
                </div>
                <div class="col-12 small p-0">
                  LinkedIn
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { BIconFacebook, BIconWhatsapp, BIconInstagram, BIconTelephone, BIconChat, BIconLinkedin, BIconTwitter, BIconEnvelope, BIconGlobe2 } from 'bootstrap-icons-vue'
import VCard from 'vcard-creator'

export default {
  name: 'ContactView',
  setup () {
    const contactDetails = ref([
      {
        slug: 'thomas-davies',
        name: 'Thomas Davies',
        lastname: 'Davies',
        firstname: 'Thomas',
        email: 'tom@fluss.io',
        mobile: '+27835564333',
        position: 'Technical founder of Fluss',
        blurb: 'Passionate about business, particularly startups.<br/>Yes, I would love to have a conversation.'
      },
      {
        slug: 'elani-van-zyl',
        name: 'Elani van Zyl',
        lastname: 'van Zyl',
        firstname: 'Elani',
        email: 'elani@fluss.io',
        mobile: '+27766121991',
        position: 'Head of Marketing',
        blurb: 'Leveraging traditional, experimental and digital strategies to raise brand awareness, build trust and drive sales.'
      }, {
        slug: 'ockie-van-zyl',
        name: 'Ockie van Zyl',
        lastname: 'van Zyl',
        firstname: 'Ockie',
        email: 'ockie@fluss.io',
        mobile: '+27829252720',
        position: 'Business strategy design at Fluss',
        blurb: 'Navigating the business strategy towards world domination in smart access control.<br/>Sales growth chaser and business scaler!'
      },
      {
        slug: 'tim-joseph',
        name: 'Tim Joseph',
        lastname: 'Joseph',
        firstname: 'Tim',
        email: 'tim@fluss.io',
        mobile: '+27833930877',
        position: 'Software Developer',
        blurb: 'Making ideas a reality (and some bug squashing).<br/>Let\'s make it happen!'
      }
    ])

    return { contactDetails }
  },
  components: {
    BIconFacebook,
    BIconWhatsapp,
    BIconInstagram,
    BIconTelephone,
    BIconChat,
    BIconLinkedin,
    BIconTwitter,
    BIconEnvelope,
    BIconGlobe2
  },
  computed: {
    theContact () {
      return this.contactDetails.filter(contact => {
        if (this.$route.params.contact !== contact.slug) {
          return false
        } else {
          return true
        }
      })
    }
  },
  methods: {
    call () {
      window.location.href = 'tel:' + this.theContact[0].mobile
    },
    text () {
      window.location.href = 'sms:' + this.theContact[0].mobile
    },
    email () {
      window.location.href = 'mailto:' + this.theContact[0].email
    },
    whatsapp () {
      window.open('https://wa.me/' + this.theContact[0].mobile, '_blank')
    },
    facebook () {
      window.open('https://www.facebook.com/flusstheapp/', '_blank')
    },
    instagram () {
      window.open('https://www.instagram.com/fluss_io/', '_blank')
    },
    twitter () {
      window.open('https://twitter.com/fluss_io', '_blank')
    },
    website () {
      window.open('https://fluss.io', '_blank')
    },
    linkedin () {
      window.open('https://www.linkedin.com/company/fluss/', '_blank')
    },
    vcard () {
      // Define a new vCard
      const myVCard = new VCard()

      myVCard
      // Add personal data
        .addName(this.theContact[0].lastname, this.theContact[0].firstname)
      // Add work data
        .addCompany('Fluss Technologies')
        .addJobtitle(this.theContact[0].position)
        .addEmail(this.theContact[0].email)
        .addPhoneNumber(this.theContact[0].mobile, 'WORK')
        .addURL('https://fluss.io')

      console.log(myVCard.toString())

      // Create CSV file object and feed our
      // csvData into it
      const VCFfile = new Blob([myVCard.toString()], { type: 'text/x-vcard' })

      // Create to temporary link to initiate
      // download process
      const tempLink = document.createElement('a')

      // Download csv file
      tempLink.download = this.theContact[0].slug + '.vcf'
      const url = window.URL.createObjectURL(VCFfile)
      tempLink.href = url

      // This link should not be displayed
      tempLink.style.display = 'none'
      document.body.appendChild(tempLink)

      // Automatically click the link to trigger download
      tempLink.click()
      document.body.removeChild(tempLink)
    }
  }
}

</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.bg_image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.profile_picture_holder {
  background-color: var(--bs-gray);
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 7px solid var(--bs-white);
}
.profile_picture {
  object-fit: cover;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.pt {
  padding-top: 80px;
}
</style>
